<template>
  <section class="all-documents-view">
    <el-row :gutter="20" v-loading.fullscreen.lock="fullscreenLoading">
      <el-col :span="16">
        <div>
          <h1>Integrations</h1>
        </div>
      </el-col>
      <el-col :span="8">
        <div>
          <el-row :gutter="20">
            <el-col :span="14">
              <el-input
                class="custom-input"
                placeholder="Search..."
                prefix-icon="el-icon-search"
                size="small"
                v-model="searchInput"
              />
            </el-col>
            <el-col :span="10">
              <el-button type="primary" @click="showCreateAppDialog">
                <span class="create-app">
                <i class="fa fa-plus-circle"></i>
                 Create an app
                </span>
                </el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        :data="allClients"
        :fullscreen="true"
        style="width: 100%; border: 1px solid #ebeef5; margin:5px"
        v-loading="tableLoading"
      >
        <!-- Loop over columns -->
        <el-table-column
          v-for="(column, index) in clientColumns"
          :key="index"
          :prop="column.prop"
          :label="column.label"
        >
          <template slot-scope="scope">
            <span
              v-if="column.prop === 'appName'"
              style="color: red !important"
            >
              <a
                href="#"
                @click.prevent="gotoClientPage(scope.row)"
                style="color: #3a86fc"
                >{{ scope.row[column.prop] }}</a
              >
            </span>
            <span v-else-if="column.prop === 'logo'">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-opencollective"
                viewBox="0 0 16 16"
              >
                <path
                  fill-opacity=".4"
                  d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"
                />
                <path
                  d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"
                />
              </svg>
              <!-- <i class="el-icon-document" style="font-size:25px"></i> -->
            </span>
            <span v-else-if="column.prop === 'status'">
              <span v-if="scope.row[column.prop]==='Active'">
                <span class="circle-shape" style="background-color:green"></span> {{ scope.row[column.prop] }}
              </span>
              <span v-else>
                <span class="circle-shape" style="background-color:black"></span> {{ scope.row[column.prop] }}
              </span>
            </span>
            <span v-else-if="column.prop === 'action'">
              <el-popconfirm
                title="Are you sure to delete this?"
                @confirm="onDeleteConfirmed(scope.row)"
              >
                <el-button slot="reference" type="danger" size="mini" plain class="px-2 ml-2">
                  <i class="el-icon-delete"></i>
                </el-button>
              </el-popconfirm>
            </span>
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <dialog-component
      :title="'Create an app'"
      :visible="clientAddDialogVisible"
      :containerWidth="'35%'"
      :containerHeight="'30%'"
      @before-close="clientAddDialogVisiblePop"
    >
      <div>
        <div class="mt-15">What's your app name?</div>
        <el-input
          placeholder="Enter an application name"
          v-model="clientAPPInfo.appName"
          clearable
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientAddDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="createApp">Create App</el-button>
      </span>
    </dialog-component>
  </section>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  name: "Developer-Portal",
  data() {
    return {
      searchInput: "",
      clientAPPInfo: {
        appName: "",
        logo: "",
      },
      clientAddDialogVisible: false,
      allClients: [],
      clientColumns: [
        {
          prop: "logo",
          label: "Logo",
        },
        {
          prop: "appName",
          label: "App Name",
        },
        {
          prop: "status",
          label: "Status",
        },
        {
          prop: "updated_at",
          label: "Last modified",
        },
        {
          prop: "created_at",
          label: "Created at",
        },
        {
          prop: "action",
          label: "Action",
        },
      ],
      fullscreenLoading: false,
      tableLoading:false,
      deletePopoverVisible: false,
    };
  },
  computed: {
    ...mapGetters("oauth", ["getclientData", "getAllClientsData","getDeleteClientAppById"]),
  },
  async mounted() {
    this.getAllClientApps();
  },
  methods: {
    clientAddDialogVisiblePop(){
      this.clientAddDialogVisible=false;
    },
    showCreateAppDialog() {
      this.clientAPPInfo.appName="";
      this.clientAPPInfo.logo="";
      this.clientAddDialogVisible = true;
    },
    async createApp() {
      this.fullscreenLoading = true;
      let params = {
        clientAppInfo: this.clientAPPInfo,
      };
      await this.$store.dispatch("oauth/createClientData", params);
      if (this.getclientData && this.getclientData.data) {
        this.getclientData.data.created_at=this.getDateFormat(this.getclientData.data.created_at)
        this.getclientData.data.updated_at=this.getDateFormat(this.getclientData.data.updated_at)
        this.allClients.push(this.getclientData.data);
        this.$notify({
            title: "Success",
            message: "App created Successfully",
            type: "success",
          });
      }else{
        this.$notify({
            title: "Error",
            message: "Error in creating App",
            type: "error",
          });
      }
      this.clientAddDialogVisible = false;
      this.fullscreenLoading = false;
    },
    async getAllClientApps() {
      this.fullscreenLoading = true;
      this.allClients = [];
      await this.$store.dispatch("oauth/fetchAllClientApps");
      if (
        this.getAllClientsData &&
        this.getAllClientsData.data &&
        this.getAllClientsData.data.length > 0
      ) {
        this.getAllClientsData.data.map(async (client) => {
          client.created_at = await this.getDateFormat(client.created_at);
          client.updated_at = await this.getDateFormat(client.updated_at);
        });
        this.allClients = this.getAllClientsData.data;
      }
      this.fullscreenLoading = false;
    },
    gotoClientPage(row) {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.$router
          .push(`/developer/app-detail/${row._id}/portal`)
          .finally(() => {
            console.log("pushed to new route");
          });
      }, 1000);
    },
    async onDeleteConfirmed(row) {
      this.fullscreenLoading = true;
      await this.$store.dispatch("oauth/deleteClientApp",{appId:row._id});
      if(this.getDeleteClientAppById){
        this.$notify({
            title: "Success",
            message: "App deleted Successfully",
            type: "success",
          });
          await this.getAllClientApps();
      }else{
        this.$notify({
            title: "Error",
            message: "Error in deleting App",
            type: "error",
          });
          await this.getAllClientApps();
      }
      this.fullscreenLoading = false;
    },
    getDateFormat(date) {
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate)) {
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
        const day = String(parsedDate.getDate()).padStart(2, "0");
        const hours = String(parsedDate.getHours()).padStart(2, "0");
        const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
        const seconds = String(parsedDate.getSeconds()).padStart(2, "0");

        const formattedDate = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      } 
    },
  },
};
</script>
<style scoped>
.circle-shape{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%; 
}
.el-input--small .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.el-button--primary {
  height: 32px !important;
}
.create-app {
  position: relative;
  bottom:5px;
}
</style>
    